import { UrlObject } from "url";

import { Box, Center, chakra, Container } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { NextLink } from "~/components/alias/NextLink";
import { pagesPath, staticPath } from "~generated/$path";

export default function NotFoundPage() {
  const { asPath } = useRouter();
  const [to, setTo] = React.useState<UrlObject>(pagesPath.$url());

  React.useEffect(() => {
    if (asPath.startsWith("/blog")) {
      setTo(pagesPath.blog.$url());
    } else if (asPath.startsWith("/admin")) {
      setTo(pagesPath.admin.$url());
    }
  }, [asPath]);

  return (
    <Container mt={20}>
      <Center>
        <Box textAlign="center">
          <chakra.img maxW={60} src={staticPath.illustration.not_found_svg} />
          <Box mt={10}>ページが見つかりませんでした</Box>

          <NextLink href={to} passHref>
            <ChakraButton as="a" mt={10}>
              TOPページへ
            </ChakraButton>
          </NextLink>
        </Box>
      </Center>
    </Container>
  );
}
